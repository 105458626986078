export default ({ nextState }) => {
  let { value } = nextState;
  value = value.toUpperCase();

  const phoneMaskRegex = /^(\+)?(1)?[- ]?\(?[_]{3}\)?[- ]?[_]{3}[- ]?[_]{4}$/;
  const postalCodeMaskRegex = /^[_]{3} ?[_]{3}$/;
  const dateMaskRegex = /^[_]{4}(\/[_]{2}){2}$/;
  const shortDateMaskRegex = /^[_]{4}\/[_]{2}$/;
  const healthInsuranceRegex = /^([_]{4} ){2}[_]{4}$/;

  if (
    phoneMaskRegex.test(value) ||
    postalCodeMaskRegex.test(value) ||
    dateMaskRegex.test(value) ||
    shortDateMaskRegex.test(value) ||
    healthInsuranceRegex.test(value)
  ) {
    value = '';
  }

  return {
    ...nextState,
    value,
  };
};
