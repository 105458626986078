// vendors
import { css, createGlobalStyle } from 'styled-components';
import { rem, em } from 'polished';

import {
  fontFamilies,
  fontColors,
  fontSizes,
  lineHeights,
  fontWeights,
} from './typography';
import { IBMPlexSansFontFace } from './fontFaces';
import { greaterThan } from '../utils/mediaQuery';
import breakpoints from './breakpoints';
import colors from './colors';
import zIndices from './zIndices';

export const rootStyles = css``;

export const htmlStyles = css`
  background-color: ${colors.white};
`;

export const bodyStyles = css`
  color: ${fontColors.body};
  font-weight: ${fontWeights.body};
  font-size: ${rem(fontSizes.body[0])};
  font-family: ${fontFamilies.body};
  line-height: ${lineHeights.body[0]};

  ${greaterThan(breakpoints.fonts[0])} {
    font-size: ${rem(fontSizes.body[1])};
    line-height: ${lineHeights.body[1]};
  }

  ${greaterThan(breakpoints.fonts[1])} {
    font-size: ${rem(fontSizes.body[2])};
    line-height: ${lineHeights.body[2]};
  }

  &.Modal--open {
    overflow: hidden;
  }

  .snipcart-modal__container {
    z-index: ${zIndices.modal} !important;
  }

  .snipcart-item-quantity__label,
  .snipcart-item-quantity__quantity {
    opacity: 0;

    pointer-events: none;
  }
`;

export const h1Style = css`
  margin-bottom: ${em(48, fontSizes.h1[0])};

  color: ${fontColors.heading};
  font-weight: ${fontWeights.heading};
  font-size: ${rem(fontSizes.h1[0])};
  line-height: ${lineHeights.heading[0]};

  word-break: initial;

  ${greaterThan(breakpoints.fonts[0])} {
    margin-bottom: ${em(48, fontSizes.h1[1])};

    font-size: ${rem(fontSizes.h1[1])};
    line-height: ${lineHeights.heading[1]};
  }

  ${greaterThan(breakpoints.fonts[1])} {
    font-size: ${rem(fontSizes.h1[2])};
    line-height: ${lineHeights.heading[2]};
  }

  ::before {
    content: '_';
  }
`;

export const h2Style = css`
  margin-top: ${em(80, 38)};
  margin-bottom: ${em(56, 38)};

  color: ${fontColors.heading};
  font-weight: ${fontWeights.heading};
  font-size: ${rem(fontSizes.h2[0])};
  line-height: ${lineHeights.heading[0]};

  word-break: initial;

  ${greaterThan(breakpoints.fonts[0])} {
    font-size: ${rem(fontSizes.h2[1])};
    line-height: ${lineHeights.heading[1]};
  }

  ${greaterThan(breakpoints.fonts[1])} {
    font-size: ${rem(fontSizes.h2[2])};
    line-height: ${lineHeights.heading[2]};
  }
`;

export const withFrontUnderline = css`
  ::before {
    content: '_';
  }
`;

export const introStyle = css`
  margin-bottom: 1.5em;

  color: ${colors.brownGrey};
  font-weight: ${fontWeights.body};
  font-size: ${rem(fontSizes.intro[0])};
  line-height: ${lineHeights.intro[0]};

  ${greaterThan(breakpoints.fonts[0])} {
    font-size: ${rem(fontSizes.intro[1])};
    line-height: ${lineHeights.intro[1]};
  }

  ${greaterThan(breakpoints.fonts[1])} {
    font-size: ${rem(fontSizes.intro[2])};
    line-height: ${lineHeights.intro[2]};
  }
`;

export const linkStyle = css`
  display: inline-block;
  text-underline-offset: 4px;

  color: ${colors.azure};

  :hover,
  :focus {
    text-decoration: none;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${IBMPlexSansFontFace};

  :root {
    ${rootStyles};
  }

  html {
    ${htmlStyles};

    background-color: ${({ backgroundColor }) => backgroundColor}

    /* background-color: ${({ greyBackground }) =>
      greyBackground && colors.veryLightPink} */
  }

  body {
    ${bodyStyles};
  }
`;
