import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { lighten, rem, em } from 'polished';

import theme from '../../styles/theme';

const FormHelperText = styled.p`
  margin-top: ${em(8)};
  margin-bottom: 0;

  color: ${theme.colors.brownGrey};
  font-weight: ${theme.fontWeights.body};
  font-size: ${rem(16)};
  line-height: ${theme.lineHeights.body[0]};

  ${(props) =>
    props.disabled &&
    css`
      color: ${theme.colors.disabled};
    `}

  ${(props) =>
    props.error &&
    css`
      color: ${theme.colors.error};
    `}

  ${(props) =>
    props.error &&
    props.disabled &&
    css`
      color: ${lighten(0.16, theme.colors.error)};
    `}
`;

export default FormHelperText;

FormHelperText.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

FormHelperText.defaultProps = {
  disabled: false,
  error: false,
};
