import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';

import Checkbox from '../Checkbox';
import FormHelperText from '../FormHelperText';
import { linkStyle } from '../../styles/global';

const PrivacyPolicyCheckbox = ({ name, onChange, checked, error }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-flow: column;
      `}
    >
      <Checkbox
        name={name}
        onChange={onChange}
        checked={checked}
        css={css`
          align-items: flex-start;

          & > div {
            margin-top: 4px;
          }
        `}
      >
        <span>
          En remplissant ce formulaire, je confirme que ces informations sont
          bien les miennes, je consens à les fournir et comprends que celles-ci
          seront traitées selon la{' '}
          {/* Technically we should use <Link> component from Gatsby here
              but since we want to open an internal link in a new tab, we don't have any choice 
              to use the default HTML anchor element */}
          <a
            href='/politique-de-confidentialite'
            target='_blank'
            css={linkStyle}
          >
            Politique de confidentialité
          </a>{' '}
          de Maxillo Québec.
        </span>
      </Checkbox>

      {error && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );
};

PrivacyPolicyCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  error: PropTypes.string,
};
PrivacyPolicyCheckbox.defaultProps = {
  onChange: () => {},
  checked: false,
  error: null,
};

export default PrivacyPolicyCheckbox;
