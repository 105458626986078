import React from 'react';
import PropTypes from 'prop-types';
import { em } from 'polished';

import 'styled-components/macro';

import randomString from '../../utils/randomString';

import FormLabel from '../FormLabel';
import TextArea from '../TextArea';
import FormHelperText from '../FormHelperText';

const TextAreaField = ({
  label,
  id,
  name,
  value,
  rows,
  cols,
  wrap,
  minLength,
  maxLength,
  onChange,
  onBlur,
  disabled,
  error,
  placeholder,
  helperText,
  ...rest
}) => {
  const textAreaId = id || randomString();
  const helperTextId = helperText ? randomString() : undefined;

  return (
    <FormLabel
      htmlFor={textAreaId}
      disabled={disabled}
      css={`
        display: grid;
        grid-template-rows: auto 1fr;

        textarea {
          margin-top: ${em(8)};
        }
      `}
      {...rest}
    >
      {label}

      <TextArea
        name={name}
        value={value}
        rows={rows}
        cols={cols}
        wrap={wrap}
        minLength={minLength}
        maxLength={maxLength}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        error={error}
        placeholder={placeholder}
      />

      {helperText && (
        <FormHelperText id={helperTextId} disabled={disabled} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormLabel>
  );
};

TextAreaField.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string,
  rows: PropTypes.string,
  cols: PropTypes.string,
  minLength: PropTypes.string,
  maxLength: PropTypes.string,
  wrap: PropTypes.oneOf(['hard', 'soft', 'off']),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
};

TextAreaField.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  id: undefined,
  rows: undefined,
  cols: undefined,
  minLength: undefined,
  maxLength: undefined,
  wrap: undefined,
  disabled: false,
  error: false,
  placeholder: null,
  helperText: null,
};

export default TextAreaField;
