import styled from 'styled-components';
import { rem } from 'polished';

import { lessThan, greaterThan } from '../../utils/mediaQuery';
import breakpointsRange from '../../utils/breakpointsRange';
import theme from '../../styles/theme';

export const Stack = styled.div`
  ${theme.layouts.stack([50, 72], theme.breakpoints.fonts)}
`;

export const Grid = styled.div`
  ${theme.layouts.grid('450px', rem(32))}
`;

export const HeadingContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

export const Heading = styled.h1`
  margin-bottom: 0;

  color: ${theme.colors.primary};
  font-weight: ${theme.fontWeights.body};

  ${breakpointsRange(
    [{ prop: 'fontSize', sizes: [30, 56] }],
    theme.breakpoints.fonts
  )};

  ::before {
    content: '_';
  }
`;

export const Fieldset = styled.fieldset`
  position: relative;

  padding: 0;

  border: 0;

  appearance: none;
`;

export const FieldsetContent = styled.div`
  color: ${theme.colors.brownGrey};

  ${lessThan(theme.breakpoints.fonts[0])} {
    padding-right: ${rem(24)};
    padding-left: ${rem(24)};
  }

  ${greaterThan(1356)} {
    padding: 0;
  }
`;

export const SubFieldsetLegend = styled.legend`
  position: absolute;
  top: 0;
  left: 0;

  margin-top: ${rem(15)};

  color: ${theme.colors.primary};
  font-weight: ${theme.fontWeights.medium};
`;

export const SubFieldsetContent = styled.div`
  display: grid;
  grid-template-rows: 85px auto;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;

export const Legend = styled.legend`
  box-sizing: content-box;

  width: 100%;

  margin-right: -${rem(26)};
  ${breakpointsRange(
    [{ prop: 'marginBottom', sizes: [30, 46] }],
    theme.breakpoints.fonts
  )};
  margin-left: -${rem(26)};
  padding: ${rem(12)} ${rem(26)};

  color: ${theme.colors.primary};
  font-weight: ${theme.fontWeights.body[0]};
  line-height: ${theme.checkedInput.lineHeights[0]};
  text-transform: uppercase;

  background-color: ${theme.colors.mineShaft400};
  border-radius: 50px;

  ${breakpointsRange(
    [{ prop: 'fontSize', sizes: [13, 19] }],
    theme.breakpoints.fonts
  )};

  ${lessThan(theme.breakpoints.fonts[0])} {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  ${greaterThan(theme.breakpoints.fonts[0])} {
    line-height: ${theme.checkedInput.lineHeights[1]};
  }
`;
