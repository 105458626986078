import { css } from 'styled-components';

import IBMPlexSansLightwoff2 from '../fonts/IBMPlexSans/IBMPlexSans-Light.woff2';
import IBMPlexSansLightwoff from '../fonts/IBMPlexSans/IBMPlexSans-Light.woff';
import IBMPlexSansRegularwoff2 from '../fonts/IBMPlexSans/IBMPlexSans-Regular.woff2';
import IBMPlexSansRegularwoff from '../fonts/IBMPlexSans/IBMPlexSans-Regular.woff';
import IBMPlexSansItalicwoff2 from '../fonts/IBMPlexSans/IBMPlexSans-Italic.woff2';
import IBMPlexSansItalicwoff from '../fonts/IBMPlexSans/IBMPlexSans-Italic.woff';
import IBMPlexSansMediumwoff2 from '../fonts/IBMPlexSans/IBMPlexSans-Medium.woff2';
import IBMPlexSansMediumwoff from '../fonts/IBMPlexSans/IBMPlexSans-Medium.woff';
import IBMPlexSansMediumItalicwoff2 from '../fonts/IBMPlexSans/IBMPlexSans-MediumItalic.woff2';
import IBMPlexSansMediumItalicwoff from '../fonts/IBMPlexSans/IBMPlexSans-MediumItalic.woff';
import IBMPlexSansSemiBoldwoff2 from '../fonts/IBMPlexSans/IBMPlexSans-SemiBold.woff2';
import IBMPlexSansSemiBoldwoff from '../fonts/IBMPlexSans/IBMPlexSans-SemiBold.woff';
import IBMPlexSansSemiBoldItalicwoff2 from '../fonts/IBMPlexSans/IBMPlexSans-SemiBoldItalic.woff2';
import IBMPlexSansSemiBoldItalicwoff from '../fonts/IBMPlexSans/IBMPlexSans-SemiBoldItalic.woff';

export const IBMPlexSansFontFace = css`
  @font-face {
    font-weight: 300;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    src: local('IBM Plex Sans'), local('IBMPlexSans'),
      url(${IBMPlexSansLightwoff2}) format('woff2'),
      url(${IBMPlexSansLightwoff}) format('woff');
  }

  @font-face {
    font-weight: 400;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    src: local('IBM Plex Sans'), local('IBMPlexSans'),
      url(${IBMPlexSansRegularwoff2}) format('woff2'),
      url(${IBMPlexSansRegularwoff}) format('woff');
  }

  @font-face {
    font-weight: 400;
    font-family: 'IBM Plex Sans';
    font-style: italic;
    src: local('IBM Plex Sans'), local('IBMPlexSans'),
      url(${IBMPlexSansItalicwoff2}) format('woff2'),
      url(${IBMPlexSansItalicwoff}) format('woff');
  }

  @font-face {
    font-weight: 500;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    src: local('IBM Plex Sans'), local('IBMPlexSans'),
      url(${IBMPlexSansMediumwoff2}) format('woff2'),
      url(${IBMPlexSansMediumwoff}) format('woff');
  }

  @font-face {
    font-weight: 500;
    font-family: 'IBM Plex Sans';
    font-style: italic;
    src: local('IBM Plex Sans'), local('IBMPlexSans'),
      url(${IBMPlexSansMediumItalicwoff2}) format('woff2'),
      url(${IBMPlexSansMediumItalicwoff}) format('woff');
  }

  @font-face {
    font-weight: 600;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    src: local('IBM Plex Sans'), local('IBMPlexSans'),
      url(${IBMPlexSansSemiBoldwoff2}) format('woff2'),
      url(${IBMPlexSansSemiBoldwoff}) format('woff');
  }

  @font-face {
    font-weight: 600;
    font-family: 'IBM Plex Sans';
    font-style: italic;
    src: local('IBM Plex Sans'), local('IBMPlexSans'),
      url(${IBMPlexSansSemiBoldItalicwoff2}) format('woff2'),
      url(${IBMPlexSansSemiBoldItalicwoff}) format('woff');
  }
`;

export default { IBMPlexSansFontFace };
