import React from 'react';

const IconCheckMark = (props) => (
  <svg
    version='1.0'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 56.58 46.97'
    {...props}
  >
    <path
      fill='currentColor'
      stroke='none'
      d='M0,21.39l9.36-7.26,12,14.64L47.51,0l9.07,7.57L20.93,47Z'
    />
  </svg>
);

export default IconCheckMark;
