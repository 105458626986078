/* stylelint-disable no-descending-specificity */
import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import theme from '../../styles/theme';
import { speed } from '../../styles/animation';
import { greaterThan } from '../../utils/mediaQuery';
import breakpoints from '../../styles/breakpoints';
import responsiveStyle from '../../utils/responsiveStyle';

export const defaultStyle = css`
  width: inherit;
  padding: ${theme.spaces.s0}rem;

  ${greaterThan(breakpoints.fonts[0])} {
    padding: ${theme.spaces.s0}rem ${theme.spaces.sm1}rem;
  }

  ${greaterThan(breakpoints.fonts[1])} {
    padding: ${theme.spaces.s0}rem ${theme.spaces.sm2}rem;
  }

  color: ${theme.fontColors.body};
  font-weight: ${theme.fontWeights.body};
  line-height: ${theme.button.lineHeights[0]};
  ${responsiveStyle('fontSize', theme.textInput.fontSizes, breakpoints.fonts)};

  background-color: ${theme.colors.primary};
  border: 0;

  outline: 2px solid transparent;
  outline-offset: 3px;

  transition-timing-function: ease;
  transition-duration: ${speed.superfast};
  transition-property: background-color;

  appearance: none;

  will-change: background-color;

  :focus {
    background-color: ${theme.colors.azure100};

    ${(props) =>
      !props.error &&
      css`
        outline: 2px solid ${theme.colors.wildSand800};
      `}
  }

  :disabled {
    color: ${theme.colors.brownGrey700};

    background-color: ${theme.colors.disabled};

    ${(props) =>
      props.error &&
      css`
        outline: 2px solid ${lighten(0.16, theme.colors.error)};
      `}
  }

  ${(props) =>
    props.error &&
    css`
      outline: 2px solid ${theme.colors.error};
    `}
`;

const StyledTextInput = styled.input`
  ${defaultStyle};
`;

export default StyledTextInput;
