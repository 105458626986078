/* stylelint-disable no-descending-specificity */
import styled from 'styled-components';
import { hideVisually } from 'polished';
import theme from '../../styles/theme';
import { speed, easing } from '../../styles/animation';
import { greaterThan } from '../../utils/mediaQuery';
import breakpoints from '../../styles/breakpoints';
import responsiveStyle from '../../utils/responsiveStyle';

export const HiddenRadioButton = styled.input.attrs({ type: 'radio' })`
  ${hideVisually()};
`;

export const RadioButtonLabel = styled.label`
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: left;

  color: ${theme.checkedInput.color};
  font-weight: ${theme.fontWeights.body};

  ${responsiveStyle(
    'fontSize',
    theme.checkedInput.fontSizes,
    breakpoints.fonts
  )};
  line-height: ${theme.checkedInput.lineHeights[0]};
  text-decoration: none;
  word-break: keep-all;

  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${greaterThan(breakpoints.fonts[0])} {
    line-height: ${theme.checkedInput.lineHeights[1]};
  }
`;

const StyledRadioButton = styled.div`
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${responsiveStyle('minWidth', theme.checkedInput.size, breakpoints.fonts)};
  ${responsiveStyle('height', theme.checkedInput.size, breakpoints.fonts)};

  ${responsiveStyle(
    'marginRight',
    theme.checkedInput.marginRight,
    breakpoints.fonts
  )};

  background-color: ${theme.colors.primary};
  border: 0;
  border-radius: 50%;

  cursor: pointer;

  ::before {
    position: absolute;

    width: calc(100% + 8px);
    height: calc(100% + 8px);

    background-color: transparent;
    border: 2px solid ${theme.colors.wildSand800};
    border-radius: 50%;

    transform: scale(0);
    opacity: 0;

    transition-timing-function: ${easing.inOutBack};
    transition-duration: ${speed.fast};
    transition-property: opacity, transform;

    content: '';

    will-change: opacity, transform;
  }

  ::after {
    position: absolute;

    ${responsiveStyle('width', [8, 14], breakpoints.fonts)};
    ${responsiveStyle('height', [8, 14], breakpoints.fonts)};

    background-color: ${theme.colors.mineShaft};
    border-radius: 50%;

    transition-timing-function: ${easing.inOutBack};
    transition-duration: ${speed.fast};
    transition-property: opacity, transform;

    content: '';

    will-change: opacity, transform;
  }

  ${HiddenRadioButton}:not(:checked) ~ &::after {
    transform: scale(0);
    opacity: 0;
  }

  ${HiddenRadioButton}:checked ~ &::after {
    transform: scale(1);
    opacity: 1;
  }

  ${HiddenRadioButton}:focus ~ &::before {
    transform: scale(1);
    opacity: 1;
  }

  ${HiddenRadioButton}:disabled ~ & {
    background-color: ${theme.colors.disabled};

    cursor: not-allowed;
  }

  ${HiddenRadioButton}:disabled ~ &::after {
    background-color: ${theme.colors.brownGrey700};
  }
`;

export default StyledRadioButton;
