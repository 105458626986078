// vendors
import styled from 'styled-components';
import { em } from 'polished';

export const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: ${em(24)};

  text-align: center;
`;

export const Link = styled.a`
  color: currentColor;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;

  :hover,
  :focus {
    text-decoration: none;
  }
`;
