import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';

import theme from '../../styles/theme';

const FormLabel = styled.label`
  color: ${(props) =>
    props.disabled ? theme.colors.disabled : theme.colors.primary};

  font-weight: ${theme.fontWeights.body};
  font-size: ${rem(theme.fontSizes.body[0])};
  line-height: ${16 / theme.fontSizes.body[0]};
`;

FormLabel.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};
FormLabel.defaultProps = {
  disabled: false,
};

export default FormLabel;
