import React from 'react';
import PropTypes from 'prop-types';

import randomString from '../../utils/randomString';

import StyledCheckbox, {
  HiddenCheckbox,
  CheckboxLabel,
  CheckMark,
} from './Checkbox.styles';

const Checkbox = ({
  children,
  id,
  name,
  value,
  checked,
  disabled,
  onChange,
  onBlur,
  ...rest
}) => {
  const customId = id || randomString();

  return (
    <CheckboxLabel htmlFor={customId} disabled={disabled} {...rest}>
      <HiddenCheckbox
        id={customId}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
      />
      <StyledCheckbox>
        <CheckMark />
      </StyledCheckbox>
      {children}
    </CheckboxLabel>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  id: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  id: undefined,
  onChange: () => {},
  onBlur: () => {},
};

export default Checkbox;
