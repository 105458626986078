import styled, { css } from 'styled-components';
import { hideVisually } from 'polished';
import theme from '../../styles/theme';
import { speed, easing } from '../../styles/animation';
import { greaterThan } from '../../utils/mediaQuery';
import breakpoints from '../../styles/breakpoints';
import responsiveStyle from '../../utils/responsiveStyle';
import IconCheckMark from '../../images/IconCheckMark';

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  ${hideVisually}
`;

export const CheckMark = styled(IconCheckMark)`
  position: absolute;

  height: 50%;

  margin-top: 3%;

  transform: scale(0);
  opacity: 0;

  transition-timing-function: ${easing.inOutBack};
  transition-duration: ${speed.fast};
  transition-property: opacity, transform;

  will-change: opacity, transform;

  path {
    fill: ${theme.colors.mineShaft};
  }
`;

export const CheckboxLabel = styled.label`
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: left;
  ${responsiveStyle('gap', theme.checkedInput.marginRight, breakpoints.fonts)}

  color: ${theme.checkedInput.color};
  font-weight: ${theme.fontWeights.body};

  ${responsiveStyle(
    'fontSize',
    theme.checkedInput.fontSizes,
    breakpoints.fonts
  )};
  line-height: ${theme.checkedInput.lineHeights[0]};
  text-decoration: none;

  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${greaterThan(breakpoints.fonts[0])} {
    line-height: ${theme.checkedInput.lineHeights[1]};
  }
`;

const StyledCheckbox = styled.div`
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${responsiveStyle('minWidth', theme.checkedInput.size, breakpoints.fonts)};
  ${responsiveStyle('height', theme.checkedInput.size, breakpoints.fonts)};

  background-color: ${theme.colors.primary};
  border: 0;
  outline: 0;

  cursor: pointer;

  appearance: none;

  :-ms-check {
    display: none;
  }

  ::before {
    position: absolute;

    width: calc(100% + 10px);
    height: calc(100% + 10px);

    background-color: transparent;
    border: 2px solid ${theme.colors.wildSand800};

    transform: scale(0);
    opacity: 0;

    transition-timing-function: ${easing.inOutBack};
    transition-duration: ${speed.fast};
    transition-property: opacity, transform;

    content: '';

    will-change: opacity, transform;
  }

  ${HiddenCheckbox}:disabled ~ & {
    background-color: ${theme.colors.disabled};

    cursor: not-allowed;
  }

  ${HiddenCheckbox}:disabled ~ & > svg > path {
    fill: ${theme.colors.brownGrey700};
  }

  ${HiddenCheckbox}:focus ~ &::before,
  ${HiddenCheckbox}:checked ~ & > svg {
    transform: scale(1);
    opacity: 1;
  }
`;

export const rightPositionStyle = css`
  flex-direction: row-reverse;
  justify-content: space-between;

  ${StyledCheckbox} {
    margin-right: 0;

    ${responsiveStyle(
      'marginLeft',
      theme.checkedInput.marginRight,
      breakpoints.fonts
    )}
  }
`;

export default StyledCheckbox;
