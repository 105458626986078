// vendors
import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

// styles
import { Container, Link } from './PrivacyPolicyNote.styles';

const PrivacyPolicyNote = () => {
  return (
    <Container>
      <p>
        Voir notre{' '}
        <Link as={GatsbyLink} to='/politique-de-confidentialite'>
          politique de confidentialité
        </Link>
      </p>
      <p>
        Il est à noter que le responsable de la protection des renseignements
        personnels est Tommy Beaulieu.
        <br /> Vous pouvez le contacter par courriel à{' '}
        <Link href='mailto:info@maxilloquebec.com'>info@maxilloquebec.com</Link>
      </p>
    </Container>
  );
};

export default PrivacyPolicyNote;
