import React from 'react';
import PropTypes from 'prop-types';

import StyledRadioButton, {
  RadioButtonLabel,
  HiddenRadioButton,
} from './RadioButton.styles';
import randomString from '../../utils/randomString';

const RadioButton = ({
  children,
  name,
  value,
  checked,
  disabled,
  onChange,
  onBlur,
  id,
  describedBy,
  ...rest
}) => {
  const customId = id || randomString();

  return (
    <RadioButtonLabel htmlFor={customId} disabled={disabled} {...rest}>
      <HiddenRadioButton
        aria-describedby={describedBy}
        id={customId}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
      />
      <StyledRadioButton />
      {children}
    </RadioButtonLabel>
  );
};

RadioButton.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  describedBy: PropTypes.string,
};
RadioButton.defaultProps = {
  checked: false,
  disabled: false,
  id: undefined,
  describedBy: undefined,
  onChange: () => {},
  onBlur: () => {},
};

export default RadioButton;
